import { HTMLAttributeAnchorTarget } from 'react';

interface INavigation {
  primaryMenuItems: IPrimaryMenuItem[];
}

export interface IPrimaryMenuItem {
  url: string;
  icon: string;
  text: string;
  theme: 'standard';
  highlighted?: boolean;
  menuItems: IMenuItem[];
  alternativeActiveUrls?: string[];
  hideOn?: string;
}

export interface IMenuItem {
  url: string;
  icon: string;
  text: string;
  target?: HTMLAttributeAnchorTarget;
}

export const navigation: INavigation = {
  primaryMenuItems: [
    {
      url: '/stod-oss',
      icon: '',
      text: 'Stöd oss',
      theme: 'standard',
      highlighted: true,
      menuItems: [
        {
          url: '/stod-oss/bli-manadsgivare',
          icon: '',
          text: 'Bli månadsgivare'
        },
        {
          url: '/stod-oss/ge-en-gava',
          icon: '',
          text: 'Ge en gåva'
        },
        {
          url: '/stod-oss/ge-en-minnesgava',
          icon: '',
          text: 'Minnesgåva'
        },
        {
          url: '/stod-oss/minnessidor',
          icon: '',
          text: 'Minnessidor'
        },
        {
          url: '/stod-oss/kop-ett-gavobevis',
          icon: '',
          text: 'Gåvobevis'
        },
        {
          url: '/stod-oss/testamente',
          icon: '',
          text: 'Testamente'
        },
        {
          url: '/insamlingar',
          icon: '',
          text: 'Insamlingar'
        },
        {
          url: '/stod-oss/foretag',
          icon: '',
          text: 'För företag'
        }
      ],
      alternativeActiveUrls: ['/stod-oss', '/insamlingar']
    },
    {
      url: '/om-cancer',
      icon: '',
      text: 'Om cancer',
      theme: 'standard',
      highlighted: true,
      menuItems: [
        {
          url: '/om-cancer/cancersjukdomar',
          icon: '',
          text: 'Cancersjukdomar'
        },
        {
          url: '/om-cancer/symtom-och-orsaker',
          icon: '',
          text: 'Symtom och orsaker'
        },
        {
          url: '/om-cancer/behandlingar',
          icon: '',
          text: 'Behandlingar'
        },
        {
          url: '/om-cancer/undersokningar',
          icon: '',
          text: 'Upptäck cancer'
        },
        {
          url: '/om-cancer/leva-med-cancer',
          icon: '',
          text: 'Leva med cancer'
        },
        {
          url: '/om-cancer/obotlig-cancer',
          icon: '',
          text: 'Obotlig cancer'
        },
        {
          url: '/om-cancer/efter-cancer',
          icon: '',
          text: 'Efter cancer'
        },
        {
          url: '/om-cancer/statistik',
          icon: '',
          text: 'Statistik'
        },
        {
          url: '/om-cancer/lattlast',
          icon: '',
          text: 'Lättläst'
        }
      ]
    },
    {
      url: '/minska-risken',
      icon: '',
      text: 'Minska risken',
      theme: 'standard',
      highlighted: false,
      menuItems: [
        {
          url: '/minska-risken/overvikt',
          icon: '',
          text: 'Övervikt & obesitas'
        },
        {
          url: '/minska-risken/matvanor',
          icon: '',
          text: 'Matvanor'
        },
        {
          url: '/minska-risken/matmiljo',
          icon: '',
          text: 'Matmiljö'
        },
        {
          url: '/minska-risken/tobak',
          icon: '',
          text: 'Tobak'
        },
        {
          url: '/minska-risken/fysisk-aktivitet',
          icon: '',
          text: 'Fysisk aktivitet'
        },
        {
          url: '/minska-risken/alkohol',
          icon: '',
          text: 'Alkohol'
        },
        {
          url: '/minska-risken/solen',
          icon: '',
          text: 'Solning'
        },
        {
          url: '/minska-risken/kolla-risken',
          icon: '',
          text: 'Kolla risken'
        }
      ]
    },
    {
      url: '/forskning',
      icon: '',
      text: 'Forskning',
      theme: 'standard',
      menuItems: [
        {
          url: '/forskning/pagar',
          icon: '',
          text: 'Forskning pågår'
        },
        {
          url: '/forskning/resultat',
          icon: '',
          text: 'Forskningens framsteg'
        },
        {
          url: '/forskning/projekt',
          icon: '',
          text: 'Projektdatabas'
        },
        {
          url: '/forskning/for-forskare',
          icon: '',
          text: 'För forskare'
        }
      ]
    },
    {
      url: '/rad-och-stod',
      icon: '',
      text: 'Råd & Stöd',
      theme: 'standard',
      menuItems: [
        {
          url: '/rad-och-stod/cancerlinjen',
          icon: '',
          text: 'Cancerlinjen'
        },
        {
          url: '/rad-och-stod/fraga-cancerlinjen',
          icon: '',
          text: 'Frågor & svar'
        },
        {
          url: '/rad-och-stod/broschyrer',
          icon: '',
          text: 'Broschyrer'
        },
        {
          url: '/rad-och-stod/cancerlinjen/nattro',
          icon: '',
          text: 'Lugn & ro i natten'
        }
      ]
    },
    {
      url: '/om-oss',
      icon: '',
      text: 'Om oss',
      theme: 'standard',
      menuItems: [
        {
          url: '/om-oss/besegra-cancer',
          icon: '',
          text: 'Vad vi gör'
        },
        {
          url: '/om-oss/mal-och-vision',
          icon: '',
          text: 'Vad vi vill'
        },
        {
          url: '/om-oss/arsberattelse',
          icon: '',
          text: 'Hit går pengarna'
        },
        {
          url: 'https://jobb.cancerfonden.se/',
          icon: '',
          text: 'Jobba hos oss',
          target: '_blank'
        },
        {
          url: '/om-oss/kontakt',
          icon: '',
          text: 'Kontakta oss'
        }
      ]
    }
  ]
};
