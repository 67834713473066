import React, { useMemo } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { heading3, sectionHeader } from '~/App/helpers/mixins';
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { AccentColor } from '~/types/Block';
import { RelatedPageItem } from '~/types/RelatedBlock';

type CardProps = {
  accentColor: AccentColor;
};

const Card = styled.div<CardProps>`
  position: relative;
  height: 100%;
  padding: 2rem 2.5rem;
  background-color: ${({ theme, accentColor }) => {
    switch (accentColor) {
      case 'blue':
        return theme.themeColors.accentBlue;
      case 'green':
        return theme.themeColors.accentGreen;
      case 'pink':
        return theme.themeColors.accentPink;
      case 'yellow':
        return theme.themeColors.accentYellow;
      default:
        break;
    }
    return null;
  }};
  border-radius: 2px;
`;

const ImageContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 1.75rem auto;
`;

const PictureElement = styled.picture`
  display: block;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Legend = styled.div`
  ${sectionHeader};
  color: ${({ theme }) => theme.colors.white};
  margin-top: 0.25rem;
`;

const Heading = styled.div`
  ${heading3};
  font-size: 1.375rem;
  line-height: 1.227272727;
  color: ${({ theme }) => theme.themeColors.primary};
  margin-top: 0.25rem;

  ${mq('≥small')`
    font-size: 1.75rem;
    line-height: 1.214285714;
  `};
`;

const Content = styled.div`
  color: ${({ theme }) => theme.themeColors.primary};
  margin-top: 0.25rem;

  a {
    color: ${({ theme }) => theme.themeColors.primary};
  }

  .arrowLink,
  .externalLink,
  .downloadLink {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

type Props = {
  item: RelatedPageItem;
  accentColor?: AccentColor;
};

export function GuideCard({ item, accentColor = 'pink' }: Props) {
  const imageUrl = item.preview?.image?.secure_url;
  const heading = item.preview?.heading;
  const preamble = item.preview?.preamble;
  const legend = item.preview?.legend;

  return useMemo(
    () => (
      <Card accentColor={accentColor}>
        {imageUrl && (
          <ImageContainer>
            <PictureElement>
              <source
                srcSet={`
                ${mediaHelper.getUrl(imageUrl, {
                  width: 400,
                  height: 340,
                  crop: 'pad',
                  quality: 100
                })} 2x,
                ${mediaHelper.getUrl(imageUrl, {
                  width: 200,
                  height: 170,
                  crop: 'pad',
                  quality: 100
                })} 1x
              `}
              />
              <Image
                src={mediaHelper.getUrl(imageUrl, {
                  width: 400,
                  height: 340,
                  crop: 'pad',
                  quality: 100
                })}
                alt={item.preview?.imageAlt}
              />
            </PictureElement>
          </ImageContainer>
        )}
        {legend && <Legend>{legend}</Legend>}
        {heading && <Heading>{heading}</Heading>}
        {preamble && (
          <Content>
            <HtmlContent dangerouslySetInnerHTML={{ __html: preamble }} />
          </Content>
        )}
      </Card>
    ),
    [accentColor, heading, imageUrl, legend, preamble, item.preview?.imageAlt]
  );
}
