import React, { useCallback, useEffect } from 'react';

import styled from 'styled-components';
import { siteUrl } from '~/App/helpers/env';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';
import { heading2, preamble } from '~/App/helpers/mixins';

// Shared components
import {
  ImageWrapper,
  ProductImage,
  Content
} from '~/App/shared/components/ThanksPage';

import { LargeShareButtons } from '~/App/shared/components/LargeShareButtons/LargeShareButtons';
import { BankId } from './BankId';
import { ICommerceThankYouOrderLine } from '~/types/ICommerceOrderLine';
import { ICommerceThankYou } from '~/types/ICommerceOrder';
import { OrderLineProduct } from './OrderLineProduct';
import { useShoppingCart } from '~/App/contexts/ShoppingCart';
import { mq } from '~/App/helpers/mq';
import { TinyHeader } from '~/App/components/TinyHeader';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

const Heading1 = styled.h1`
  ${heading2};
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 60.5rem;
  margin: 5rem auto 0 auto;
  padding: 0 1rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  ${mq('<mediumLarge')`
    margin-right: 1rem;
    margin-left: 1rem;
  `};
`;

const StyledWrapper = styled(Wrapper)`
  margin-top: 1.5rem;
`;

const Preamble = styled.p`
  ${preamble};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 0.5rem;
  white-space: pre-line;
`;

const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const OrderInfoText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderInfoParagraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.75rem;

  ${mq('<mediumLarge')`
    font-size: 1rem;
    line-height: 2rem;
  `};
`;

const OrderInfoHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: auto;
  padding-bottom: 0.5rem;

  ${mq('<mediumLarge')`
    font-size: 1.125rem;
  `};
`;

const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackLinkWrapper = styled.div`
  text-decoration: underline;
  justify-content: center;
  display: flex;
  text-align: center;
  margin: 32px 76px 96px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--deep-blue);
`;

const StyledContent = styled(Content)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const PageWrapper = styled.div`
  margin-top: 7rem;
`;

const Background = styled.div`
  background-color: #f0f9fc;
  padding-bottom: 3.5rem;

  ${mq('<mediumLarge')`
    padding-bottom: 2rem;
  `};
`;

type Props = {
  order: ICommerceThankYou;
};

export function CommerceThankYou({ order }: Props) {
  const shoppingCart = useShoppingCart();
  const { isAnonymous } = useAuthenticationContext();

  useEffect(() => {
    shoppingCart.finish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultImage =
    '//res.cloudinary.com/cancerfonden/image/upload/v1574943685/assets/heart-and-hands-square.png';

  const renderProduct = useCallback(
    (orderLine: ICommerceThankYouOrderLine, index: number) => (
      <OrderLineProduct orderLine={orderLine} key={`order_line_${index}`} />
    ),
    []
  );

  return (
    <Background>
      <TinyHeader />
      <PageWrapper>
        <Wrapper>
          <ImageWrapper>
            <ProductImage
              src={mediaHelper.getUrl(defaultImage, {
                transformation: [
                  {
                    width: 280,
                    height: 280,
                    crop: 'lpad',
                    gravity: 'center'
                  },
                  {
                    width: 92,
                    height: 92,
                    dpr: '2.0',
                    crop: 'pad',
                    quality: 100
                  }
                ]
              })}
            />
          </ImageWrapper>
          <Content style={{ paddingBottom: '2.5rem' }}>
            {order.contactName ? (
              <Heading1 children={`Tack för ditt köp, ${order.contactName}!`} />
            ) : (
              <Heading1 children={`Tack för ditt köp!`} />
            )}
            <Preamble>
              Vi har skickat ditt kvitto till din e-post. Digitala gåvobevis
              skickas till din e-post i separat mejl, och fysiska produkter
              levererar vi så fort vi kan.
            </Preamble>
            <OrderInfoWrapper>
              <OrderInfoHeading>
                Ordernummer: {order.reference}
              </OrderInfoHeading>
              {order.orderLines?.map(renderProduct)}
              {order.shippingPrice !== undefined && order.shippingPrice !== null && (
                <OrderInfoText>
                  <OrderInfoParagraph>Frakt</OrderInfoParagraph>{' '}
                  <OrderInfoParagraph>
                    {order.shippingPrice} kr
                  </OrderInfoParagraph>
                </OrderInfoText>
              )}
              <OrderInfoText>
                <OrderInfoParagraph>
                  <b>Totalt</b>
                </OrderInfoParagraph>
                <OrderInfoParagraph>
                  <b>{order.totalPrice} kr</b>
                </OrderInfoParagraph>
              </OrderInfoText>
            </OrderInfoWrapper>
          </Content>
        </Wrapper>
      </PageWrapper>
      {isAnonymous && (
        <StyledWrapper>
          <StyledContent>
            <Heading1>Se info om din order</Heading1>
            <Preamble style={{ marginBottom: '1.5rem' }}>
              Ett enkelt sätt att se info om din order, samla dina gåvor och
              starta insamlingar är att ha ett konto hos oss. Logga in eller
              skapa ditt konto med BankID.
            </Preamble>
            <BankId />
          </StyledContent>
        </StyledWrapper>
      )}
      <StyledWrapper>
        <Content style={{ paddingTop: '2rem' }}>
          <ShareWrapper>
            <Heading1>Tipsa om gåvoshopen</Heading1>
            <Preamble style={{ marginBottom: '1.5rem' }}>
              Hjälp oss bli ännu fler i kampen mot cancer - tipsa andra om
              gåvoshopen. Tack!
            </Preamble>
            <LargeShareButtons
              to={siteUrl('/gavoshop/privat')}
              email={{
                subject: 'Jag har gjort en livsviktig sak!',
                body:
                  'Jag har köpt en livsviktig produkt i Cancerfondens gåvoshop. Gör det du också. \n\n https://www.cancerfonden.se/gavoshop/privat'
              }}
              x={{
                text:
                  'Jag har köpt en livsviktig produkt i Cancerfondens gåvoshop. Gör det du också.'
              }}
              facebook={true}
            />
          </ShareWrapper>
        </Content>
      </StyledWrapper>
      <BackLinkWrapper>
        <a href={siteUrl()}>Tillbaka till Cancerfonden</a>
      </BackLinkWrapper>
    </Background>
  );
}
