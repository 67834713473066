import styled, { css } from 'styled-components';

type LabelProps = {
  isEmpty?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  hasIcon?: boolean;
};

export const Label = styled.label<LabelProps>`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 12px;
  left: 12px;
  font-size: 11px;
  opacity: 1;
  color: ${({ theme }) => theme.colors.gray};
  transition: top 0.15s ease, opacity 0.15s ease;
  white-space: nowrap;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      top: 8px;
      opacity: 0;
      z-index: -1;
    `};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: ${({ theme }) => theme.colors.errorRed};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.gray};
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      left: 50px;
    `};
`;
