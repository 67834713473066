import React from 'react';

import useLogin from '~/App/shared/hooks/use-login';

import { SubHeroText } from '../../SubHeroText';
import { BankId } from '../../BankId';
import { ViewWrapper } from '../../ViewWrapper';
import { HeroVariant } from '../../Hero';

export function Private() {
  const login = useLogin();
  const heroType: HeroVariant = 'private';

  return (
    <ViewWrapper hero={heroType} footer>
      <BankId beginAuthentication={login.handlers.handleLogin}>
        <SubHeroText hero={heroType} hideMobile={false} />
      </BankId>
    </ViewWrapper>
  );
}
