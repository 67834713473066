import React, { useCallback } from 'react';
import { CancelToken } from 'axios';

import { contentApi } from '~/App/helpers/env';
import { fetch } from '~/App/helpers/http';
import { IRelatedBlock } from '~/types/RelatedBlock';
import { Related } from '~/App/shared/components/Related';

type Props = IRelatedBlock;

export function RelatedBlock({ id, ...props }: Props) {
  const fetchPage = useCallback(
    async (page: number, cancelToken?: CancelToken) => {
      const response = await fetch({
        url: contentApi('/v1/blocks'),
        params: { id: id, page: page },
        cancelToken
      });

      return response?.data?.block?.items;
    },
    [id]
  );

  return <Related id={id} {...props} itemsFor={fetchPage} />;
}
