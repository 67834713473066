import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  ComponentProps
} from 'react';
import { useNavigate } from 'react-router-dom';

// @ts-expect-error
import tippy from 'tippy.js';
import styled, { css } from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';

import {
  link,
  heading1,
  heading2,
  heading3,
  heading4,
  preamble,
  arrowLink,
  externalLink,
  primaryButton,
  outlinePrimaryButton,
  downloadLink,
  ctaButton,
  pinkPrimaryButton,
  invertedButton
} from '~/App/helpers/mixins';

type BlockProps = {
  spacingTop?: boolean;
  template?: 'newsPage' | 'content';
};

const cmsButton = css`
  ${mq('<small')`
padding: 0.5rem 1.5rem;
font-size: 0.875rem;
min-width: 142px;
line-height: 0.75rem;
`};
`;

const Block = styled.div<BlockProps>`
  margin-top: ${(props) => (props.spacingTop ? '1rem' : '0')};

  .preamble {
    ${preamble};

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  .imageCaption {
    margin: 0 0 1rem 0;
    padding: 0.5rem 0;
    font-size: 0.75rem;
    line-height: 1.583333333;
    font-style: italic;
    color: ${(props) => props.theme.colors.charcoal};
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }

  figure {
    display: block;
    margin: 0.5rem 0 1rem 0;
    display: table;

    &.figureLeft {
      ${mq('≥small')`
        float: left;
        max-width: 35%;
        margin-right: 2rem;
      `};
    }

    &.figureRight {
      ${mq('≥small')`
        float: right;
        max-width: 35%;
        margin-left: 2rem;
      `};
    }

    &.figureCenter {
      ${mq('≥small')`
        margin-left: auto;
        margin-right: auto;
      `};
    }

    img {
      width: 100%;

      &.imageLeft,
      &.imageRight,
      &.imageCenter {
        float: none;
        max-width: 100%;
        margin: 0;
      }
    }

    figcaption {
      margin: 0;
      padding: 0.5rem 0 0;
      font-size: 0.75rem;
      line-height: 1.583333333;
      font-style: italic;
      color: ${(props) => props.theme.colors.charcoal};
      display: table-caption;
      caption-side: bottom;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin-top: 0.5rem;

    &.imageLeft {
      ${mq('≥small')`
        float: left;
        max-width: 35%;
        margin-right: 2rem;
        margin-bottom: 1rem;
      `};
    }

    &.imageRight {
      ${mq('≥small')`
        float: right;
        max-width: 35%;
        margin-bottom: 1rem;
        margin-left: 2rem;
      `};
    }

    &.imageCenter {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .highlighted-content {
    background-color: ${(props) => props.theme.colors.seashell};
    margin: 2rem auto 2rem auto;
    padding: 2rem 2rem 1.5rem 2rem;
    color: ${(props) => props.theme.themeColors.primary};

    h2,
    h3,
    h4 {
      margin-top: 0;
    }

    ul,
    ol {
      padding-left: 1.125rem;

      li {
        padding-left: 0.75rem;
      }
    }

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .svg-illustration {
    background-color: ${(props) => props.theme.colors.seashell};
    margin: 2rem auto 2rem auto;
    padding: 2rem 2rem 1.5rem 2rem;
    color: ${(props) => props.theme.themeColors.primary};

    ${mq('≥small')`
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
    `};

    &::before {
      display: block;
      flex-shrink: 0;
      flex-basis: 86px;
      width: 86px;
      margin: 0 auto 1.25rem auto;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;

      ${mq('≥small')`
        margin: 0 1.25rem 0 0;
      `};
    }

    &.animate-svg {
      &::before {
        display: none;
      }
    }

    .svg-container {
      flex-shrink: 0;
      flex-basis: 86px;
      display: block;
      width: 86px;
      margin: 0 auto 1.25rem auto;

      ${mq('≥small')`
        margin: 0 1.25rem 0 0;
      `};
    }

    .content {
      h2,
      h3,
      h4 {
        margin-top: 0;
      }

      ul,
      ol {
        padding-left: 1.125rem;

        li {
          padding-left: 0.75rem;
        }
      }

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.fact-owl {
      &::before {
        min-height: 104px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559032098/svg-animations/fact-owl.svg');
      }

      .svg-container {
        min-height: 104px;
      }
    }

    &.fact-endoscopy {
      &::before {
        min-height: 102px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559125029/svg-animations/fact-endoscopy.svg');
      }

      .svg-container {
        min-height: 102px;
      }
    }

    &.fact-biopsy {
      &::before {
        min-height: 123px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559053471/svg-animations/fact-biopsy.svg');
        background-size: 50px;
        background-position: 50% 0;
      }

      .svg-container {
        min-height: 209px;
      }
    }

    &.fact-tomography {
      &::before {
        min-height: 100px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559115806/svg-animations/fact-tomography.svg');
      }

      .svg-container {
        min-height: 99px;
      }
    }

    &.fact-examination {
      &::before {
        min-height: 112px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559122478/svg-animations/fact-examination.svg');
        background-size: 76px;
        background-position: 50% 0;
      }

      .svg-container {
        min-height: 125px;
      }
    }

    &.fact-mri {
      &::before {
        min-height: 92px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559115806/svg-animations/fact-mri.svg');
      }

      .svg-container {
        min-height: 92px;
      }
    }

    &.fact-xray {
      &::before {
        min-height: 93px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559120666/svg-animations/fact-xray.svg');
      }

      .svg-container {
        min-height: 93px;
      }
    }

    &.fact-ultrasound {
      &::before {
        min-height: 80px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559118315/svg-animations/fact-ultrasound.svg');
        flex-basis: 106px;
        width: 106px;

        ${mq('≥small')`
          margin-right: 1.75rem;
        `};
      }

      .svg-container {
        min-height: 80px;
      }
    }

    &.fact-testicles {
      &::before {
        min-height: 70px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559134124/svg-animations/fact-testicles.svg');
        background-size: 66px;
        background-position: 50% 0;
      }

      .svg-container {
        min-height: 70px;
      }
    }

    &.fact-papsmear {
      &::before {
        min-height: 68px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559137503/svg-animations/fact-papsmear.svg');
        flex-basis: 116px;
        width: 116px;

        ${mq('≥small')`
          margin-right: 1.75rem;
        `};
      }

      .svg-container {
        min-height: 68px;
      }
    }

    &.fact-mammography {
      &::before {
        min-height: 103px;
        background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/v1559137503/svg-animations/fact-mammography.svg');
      }

      .svg-container {
        min-height: 103px;
      }
    }
  }

  blockquote {
    ${heading2};
    font-weight: ${(props) => props.theme.fontWeights.light};
    font-style: italic;
    color: ${(props) => props.theme.themeColors.primary};
    margin: 2rem auto 2rem auto;

    &:before {
      content: '';
      display: block;
      width: 72px;
      height: 42px;
      margin: 0 0 1rem 0;
      background-color: transparent;
      background-image: url('https://res.cloudinary.com/cancerfonden/image/upload/c_scale,w_144/v1550744475/assets/cf-test-illustration-quote.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    p {
      ${heading2};
      font-weight: ${({ theme }) => theme.fontWeights.light};
      font-style: italic;
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    height: 1px;
    background: #ccc;
    background-image: linear-gradient(to right, #eee, #ccc, #eee);
  }

  em {
    font-style: italic;
  }

  h2 {
    ${heading2};
    color: ${({ template, theme }) =>
      template === 'newsPage'
        ? theme.colors.charcoal
        : theme.themeColors.primary};
  }

  h3 {
    ${heading3};
    color: ${({ template, theme }) =>
      template === 'newsPage'
        ? theme.colors.charcoal
        : theme.themeColors.primary};
  }

  h4 {
    ${heading4};
    color: ${({ theme, template }) =>
      template === 'newsPage'
        ? theme.colors.charcoal
        : theme.themeColors.primary};
  }

  h2,
  h3,
  h4,
  h5 {
    margin-top: ${({ template }) =>
      template === 'newsPage' ? '3rem' : '2rem'};
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
    word-wrap: break-word;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 2rem;
    margin-bottom: 1rem;

    ${mq('≥small')`
      font-size: 1.125rem;
    `};

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ui-tooltip {
    color: ${(props) => props.theme.themeColors.primary};
    border-bottom: ${(props) => props.theme.themeColors.primary} dashed 1px;

    &:hover {
      color: ${(props) => props.theme.themeColors.primaryHover};
    }
  }

  .responsiveVideo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    z-index: 1;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }

    + :not(.responsiveVideoCaption) {
      padding-top: 2rem;
    }

    + .responsiveVideoCaption {
      margin: 0;
      padding: 0.5rem 0 0;
      font-size: 0.75rem;
      line-height: 1.583333333;
      font-style: italic;
      color: ${(props) => props.theme.content.headingColor};

      ${mq('≥small')`
        font-size: 0.75rem;
      `};

      + * {
        padding-top: 2rem;
      }
    }
  }

  a {
    ${link};
  }

  .button--solid-ocean {
    ${primaryButton};
    ${cmsButton}
  }

  .primaryButton {
    ${primaryButton};
    ${cmsButton}
  }

  .outlinePrimaryButton {
    ${outlinePrimaryButton};
    ${cmsButton}
  }

  .ctaButton {
    ${ctaButton};
    ${cmsButton}
  }

  .pinkPrimaryButton {
    ${pinkPrimaryButton}
    ${cmsButton}
  }

  .invertedButton {
    ${invertedButton};
    ${cmsButton}
  }

  .arrowLink,
  .ui-arrowLink {
    ${arrowLink};

    ${mq('≥small')`
      line-height: 1.5rem;
    `};
  }

  .externalLink,
  .ui-externalLink {
    ${externalLink};

    ${mq('≥small')`
      line-height: 1.5rem;
    `};
  }

  .downloadLink,
  .ui-downloadLink {
    ${downloadLink};

    ${mq('≥small')`
      line-height: 1.5rem;
    `};
  }

  .roundButton {
    ${primaryButton};

    &--light {
      color: ${({ theme }) => theme.invertedButton.textColor};
      border-color: ${({ theme }) => theme.invertedButton.borderColor};
      background-color: ${({ theme }) => theme.invertedButton.backgroundColor};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.invertedButton.hoverTextColor};
        border-color: ${({ theme }) => theme.invertedButton.hoverBorderColor};
        background-color: ${({ theme }) =>
          theme.invertedButton.hoverBackgroundColor};
      }
    }

    &--lightBorder {
      border-color: ${({ theme }) => theme.outlinePrimaryButton.borderColor};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.outlinePrimaryButton.hoverTextColor};
        border-color: ${({ theme }) =>
          theme.outlinePrimaryButton.hoverBorderColor};
        background-color: ${({ theme }) =>
          theme.outlinePrimaryButton.hoverBackgroundColor};
      }
    }

    &--fullWidth {
      width: 100%;
    }

    &--large {
      font-size: 1rem;

      ${mq('≥small')`
        font-size: 1.25rem;
      `};
    }
  }

  .buttonList {
    display: block;
    padding: 0;
    margin: 1.5rem -0.5rem 1.5rem -0.5rem;

    li {
      display: block;
      margin: 0 0.5rem 1rem 0.5rem;

      ${mq('≥small')`
        display: inline-block;
        margin: 0 0.5rem 1rem 0.5rem;
      `};

      &.hiddenFromSmall {
        ${mq('≥small')`
          display: none;
        `};
      }

      &.hiddenUntilSmall {
        ${mq('<small')`
          display: none;
        `};
      }
    }

    &--centered {
      text-align: center;
    }
  }

  .featureList {
    padding-left: 0;
    margin-left: 2.5rem;
    list-style-type: none;

    li {
      position: relative;
      margin: 0 0 1rem 0;
    }

    .i-check {
      display: block;
      position: absolute;
      top: 0px;
      left: -2.3rem;
      width: 1.7rem;
      height: 1.7rem;
      background: transparent url(/images/icon-check-2019.png) no-repeat center
        center;
      background-size: contain;
    }

    .i-check-blue {
      display: block;
      position: absolute;
      top: 0px;
      left: -2.3rem;
      width: 1.7rem;
      height: 1.7rem;
      background: transparent url(/images/icon-check-2019.png) no-repeat center
        center;
      background-size: contain;
    }

    .i-check-themed {
      display: block;
      position: absolute;
      top: 0px;
      left: -2.25rem;
      width: 1.625rem;
      height: 1.625rem;
      background: transparent url(${(props) => props.theme.iconAssets.check})
        no-repeat center center;
      background-size: contain;
    }
  }

  .featureListHorizontal {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;

    ${mq('≥small')`
        flex-direction: row;
      `};

    li {
      position: relative;
      margin: 0 0 1rem 3rem;
      flex-grow: 1;
      flex-basis: 0;

      &:last-child {
        margin-bottom: 1rem;
      }
    }

    .i-check {
      display: block;
      position: absolute;
      top: 0px;
      left: -2.3rem;
      width: 1.7rem;
      height: 1.7rem;
      background: transparent url(/images/icon-check-pink.png) no-repeat center
        center;
      background-size: contain;
    }
  }

  .divider {
    font: 0/0 a;
    color: transparent;
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
  }

  .rb18StartHeroSubtitle {
    font-size: 1rem;
    font-family: 'CancerfondenSans', sans-serif;
    font-weight: 700;
    margin-bottom: 0;

    ${mq('≥small')`
      font-size: 1.6rem;
    `};
  }

  .rb18StartHeroTitle {
    ${heading1};
    color: #ca0070;
    margin-bottom: 1rem;
  }

  .rb18-designer-hero-year {
    font-family: 'CancerfondenSans', sans-serif;
    font-size: 4.5em;
    line-height: 0.7;
    font-weight: 700;
    color: #ca0070;
    margin-bottom: 0;
    padding-left: 20px;

    ${mq('≥small')`
      font-size: 5.5em;
      padding-left: 0;
    `};

    ${mq('≥large')`
      font-size: 7em;
      padding-left: 0;
    `};
  }

  .rb18-designer-hero-year-plus {
    font-family: 'CancerfondenSans', sans-serif;
    font-size: 4.5em;
    line-height: 0.7;
    font-weight: 700;
    color: #ca0070;
    margin-bottom: 0;
    padding-left: 50px;

    ${mq('≥small')`
      font-size: 5.5em;
      padding-left: 0;
    `};

    ${mq('≥large')`
      font-size: 7em;
      padding-left: 0;
    `};
  }

  .rb18-designer-hero-name {
    ${heading1};

    padding-left: 20px;

    ${mq('≥small')`
      padding-left: 0;
    `};

    ${mq('≥large')`
      padding-left: 0;
    `};
  }

  .rb18-designer-hero-body {
    font-family: 'CancerfondenSans', sans-serif;
    font-size: 1.1em;
    font-weight: 700;
    color: #000;
    margin-bottom: 0;
    line-height: 1.3;
    padding-top: 1rem;
    padding-left: 50px;

    ${mq('≥small')`
      padding-left: 0;
    `};

    ${mq('≥large')`
      padding-left: 0;
    `};
  }

  .rb18-designer-hero-subtitle {
    font-family: 'CancerfondenSans', sans-serif;
    font-size: 1.1em;
    color: #000;
    padding-left: 20px;

    ${mq('≥small')`
      font-size: 1.3em;
      padding-left: 0;
    `};

    ${mq('≥large')`
      font-size: 1.5em;
      padding-left: 0;
    `};
  }

  .rb18-designer-hero-subtitle-plus {
    font-family: 'CancerfondenSans', sans-serif;
    font-size: 1.1em;
    padding-left: 50px;

    ${mq('≥small')`
      font-size: 1.3em;
      padding-left: 0;
    `};
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .rb18-designer-hero-button {
    ${mq('<small')`
      padding-left: 20px;
    `};
  }

  .rb18DesignerQuote {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    ${mq('≥small')`
      flex-direction: row;
    `};

    .rb18DesignerQuoteText {
      font-family: 'CancerfondenSans', sans-serif;
      font-weight: 300;
      position: relative;
      padding: 1rem 0;
      font-size: 1.25rem;

      ${mq('≥small')`
        padding: 3rem 3rem 3rem 0;
      `};
    }

    .rb18DesignerQuoteBand {
      flex-basis: 140px;
      align-self: flex-end;
      min-width: 140px;
      max-width: 140px;
      max-height: 140px;
      background-color: white;
      margin-top: -100px;
      border-radius: 80px;
      background-image: url('/images/rosabandet/ribbon-2018-shadow.png');
      background-origin: content-box;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 1.5em;

      ${mq('≥small')`
        flex-basis: 220px;
        align-self: unset;
        min-width: 220px;
        max-width: 220px;
        max-height: 220px;
        margin-top: -110px;
        border-radius: 110px;
        padding: 2.5em;
      `};

      ${mq('≥medium')`
        flex-basis: 240px;
        align-self: unset;
        min-width: 240px;
        max-width: 240px;
        max-height: 240px;
        margin-top: -120px;
        border-radius: 120px;
        padding: 2.5em;
      `};

      ${mq('≥large')`
        flex-basis: 260px;
        align-self: unset;
        min-width: 260px;
        max-width: 260px;
        max-height: 260px;
        margin-top: -130px;
        border-radius: 130px;
        padding: 2.5em;
      `};
    }

    &:before {
      display: block;
      width: 0;
      content: '';
      position: absolute;
      top: -1rem;
      left: 2rem;
      border-width: 0 2rem 1rem;
      border-style: solid;
      border-color: #fef6f8 transparent;

      ${mq('≥small')`
        left: 6rem;
      `};

      ${mq('≥large')`
        left: 3rem;
      `};
    }

    ${mq('≥small')`
      font-size: 1.125rem;
    `};
  }
`;

type Props = Omit<ComponentProps<typeof Block>, 'children'> & {
  children?: string;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
};

export function HtmlContent({
  children,
  dangerouslySetInnerHTML,
  ...props
}: Props) {
  const navigate = useNavigate();
  const content = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(
    (event) => {
      const url = event.target.getAttribute('href') || '';
      const target = event.target.getAttribute('target') || '';

      const isInternal = /^\/(?!\/)/.test(url);
      const isTooltip = event.target.classList.contains('ui-tooltip');
      const isNewWindow = target === '_blank';

      if (isTooltip) {
        return event.preventDefault();
      }

      if (!url || url === '#') {
        return event.preventDefault();
      }

      if (isInternal && !isNewWindow) {
        event.preventDefault();
        navigate(url);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const element = content.current;

    if (!element) {
      return;
    }

    element.addEventListener('click', handleClick, {
      capture: false,
      passive: false
    });

    return () => {
      element.removeEventListener('click', handleClick, {
        capture: false
      });
    };
  }, [handleClick]);

  useEffect(() => {
    const element = content.current;

    if (!element) {
      return;
    }

    const elements = element.querySelectorAll('.ui-tooltip');

    if (!elements.length) {
      return;
    }

    const instance = tippy(elements, {
      arrow: true,
      theme: 'HtmlContent',
      zIndex: 5,
      trigger: 'mouseenter focus',
      maxWidth: 300
    });

    return () => {
      instance.destroyAll();
    };
  }, []);

  return useMemo(
    () => (
      <Block
        ref={content}
        dangerouslySetInnerHTML={
          dangerouslySetInnerHTML
            ? dangerouslySetInnerHTML
            : { __html: children || '' }
        }
        {...props}
      />
    ),
    [children, dangerouslySetInnerHTML, props]
  );
}
