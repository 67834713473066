import React, { useRef, useMemo, ChangeEvent } from 'react';
import styled from 'styled-components';

import { AlertMessageContainer } from '~/App/shared/components/Elements';

import { IValidation } from '~/App/shared/components/Validation';
import { FetchBankAccountValues } from '../../Forms/components/FetchBankAccount';
import { FetchBankAccountsButton } from '../../Forms/components/FetchBankAccounts.Button';
import QRAutostartTokenWrapper from '../../Forms/components/QRAutostartTokenWrapper';
import Text from '~/App/shared/components/Fields/Text';
import Select from '~/App/shared/components/Fields/Select';
import { PaymentValues, ContactCustomerValues } from '../../States';
import BankIdWrapper from '../../Forms/components/BankIdWrapper';
import { IntegrityTextArea } from '../../Forms/components/IntegrityTextArea';
import { usePreferredCustomerContactType } from '~/App/shared/hooks/use-preferred-customer-contact-type';

const availableBanks = [
  {
    name: 'CUSTOM',
    displayName: 'Fyll i kontouppgifter manuellt'
  },
  {
    name: 'OEB',
    displayName: 'DanskeBank'
  },
  {
    name: 'SHB',
    displayName: 'Handelsbanken'
  },
  {
    name: 'ICA',
    displayName: 'Ica'
  },
  {
    name: 'LFB',
    displayName: 'Länsförsäkringar'
  },
  {
    name: 'NB',
    displayName: 'Nordea'
  },
  {
    name: 'SBAB',
    displayName: 'SBAB'
  },
  {
    name: 'SEB',
    displayName: 'SEB'
  },
  {
    name: 'SKB',
    displayName: 'Skandia'
  },
  {
    name: 'SYD',
    displayName: 'Sparbanken Syd'
  },
  {
    name: 'FSPASB',
    displayName: 'Sparbankerna'
  },
  {
    name: 'FSPA',
    displayName: 'Swedbank'
  }
];

const InputWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:first-child {
    margin-top: 0.5rem;
  }
`;

type Props = {
  values: PaymentValues & ContactCustomerValues;
  fetchBankAccount: FetchBankAccountValues;
  validation: IValidation;
  showSsnField?: boolean;
  showEmailField?: boolean;
  showIntegrityText?: boolean;
};

export function AutoGiroBankIdFields({
  values,
  validation,
  fetchBankAccount,
  showSsnField = true,
  showEmailField = true,
  showIntegrityText = false
}: Props) {
  const bankIdSsn = useRef<HTMLInputElement>(null);

  const accountNumbers = useMemo(
    () =>
      fetchBankAccount.accountNumbers.map((accountNumber) => {
        const { accountNo, clearingNo, displayName } = accountNumber;

        const number = `${clearingNo}-${accountNo}`;

        return {
          value: number,
          label: `${displayName} - ${number.replace(/.(?=.{4})/g, '*')}`
        };
      }),
    [fetchBankAccount.accountNumbers]
  );

  const banks = useMemo(
    () =>
      availableBanks.map((bank) => ({
        value: bank.name,
        label: bank.displayName
      })),
    []
  );

  const waiting = useMemo(
    () =>
      fetchBankAccount.status === 'sending' ||
      fetchBankAccount.status === 'polling',
    [fetchBankAccount.status]
  );

  usePreferredCustomerContactType({
    preferred: 'automatic',
    values
  });

  if (!waiting) {
    return (
      <div>
        {showSsnField && (
          <InputWrapper>
            <Text
              name="ssn"
              type="tel"
              value={values.productOptions.customerContact.ssn}
              onBlur={validation.showError.bind(
                undefined,
                'productOptions.customerContact.ssn'
              )}
              errors={validation.errors['productOptions.customerContact.ssn']}
              isValid={validation.isValid('productOptions.customerContact.ssn')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                values.handlers.handleCustomerContactSsn(
                  event,
                  bankIdSsn.current
                );
              }}
              inputRef={bankIdSsn}
              isInvalid={validation.isInvalid(
                'productOptions.customerContact.ssn'
              )}
              maxLength="12"
              placeholder="Personnummer (ÅÅÅÅMMDDCCCC)"
              instructions="Vi hämtar och sparar dina folkbokförda uppgifter."
              required
            />
          </InputWrapper>
        )}
        {showEmailField && (
          <InputWrapper>
            <Text
              name="email"
              type="email"
              value={values.productOptions.customerContact.email}
              onBlur={validation.showError.bind(
                undefined,
                'productOptions.customerContact.email'
              )}
              errors={validation.errors['productOptions.customerContact.email']}
              isValid={validation.isValid(
                'productOptions.customerContact.email'
              )}
              onChange={values.handlers.handleCustomerContactEmail}
              isInvalid={validation.isInvalid(
                'productOptions.customerContact.email'
              )}
              placeholder="E-post"
              autoComplete="email"
              instructions="Bekräftelse skickas via e-post."
              required
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <Select
            name="bank"
            value={values.mobileBankId.bank}
            onBlur={validation.showError.bind(undefined, 'mobileBankId.bank')}
            errors={validation.errors['mobileBankId.bank']}
            options={banks}
            isValid={validation.isValid('mobileBankId.bank')}
            onChange={values.handlers.handleMobileBankIdChange}
            isInvalid={validation.isInvalid('mobileBankId.bank')}
            placeholder="Välj bank"
            required={true}
          />
        </InputWrapper>
        <InputWrapper>
          {fetchBankAccount.status === 'completed' &&
            fetchBankAccount.accountNumbers.length > 0 &&
            values.mobileBankId.bank !== 'CUSTOM' && (
              <Select
                name="accountNumber"
                value={`${values.mobileBankId.clearingNumber}-${values.mobileBankId.accountNumber}`}
                isValid={
                  validation.isValid('mobileBankId.accountNumber') &&
                  validation.isValid('mobileBankId.clearingNumber')
                }
                options={accountNumbers}
                onChange={values.handlers.handleMobileBankIdSelectChange}
              />
            )}
        </InputWrapper>
        {fetchBankAccount.status === 'completed' &&
          values.mobileBankId.bank === 'SHB' && (
            <AlertMessageContainer messageStyle="warning">
              Observera att det inte går att välja konton av typen Allkonto
              Kredit för autogirobetalningar.
            </AlertMessageContainer>
          )}

        {values.mobileBankId.bank === 'CUSTOM' && (
          <>
            <InputWrapper>
              <Text
                name="clearingNumber"
                type="tel"
                value={values.mobileBankId.clearingNumber}
                onBlur={validation.showError.bind(
                  undefined,
                  'mobileBankId.clearingNumber'
                )}
                errors={validation.errors['mobileBankId.clearingNumber']}
                isValid={validation.isValid('mobileBankId.clearingNumber')}
                onChange={values.handlers.handleMobileBankIdChange}
                isInvalid={validation.isInvalid('mobileBankId.clearingNumber')}
                placeholder="Clearingnummer"
                required
              />
            </InputWrapper>
            <InputWrapper>
              <Text
                name="accountNumber"
                type="tel"
                value={values.mobileBankId.accountNumber}
                onBlur={validation.showError.bind(
                  undefined,
                  'mobileBankId.accountNumber'
                )}
                errors={validation.errors['mobileBankId.accountNumber']}
                isValid={validation.isValid('mobileBankId.accountNumber')}
                onChange={values.handlers.handleMobileBankIdChange}
                isInvalid={validation.isInvalid('mobileBankId.accountNumber')}
                placeholder="Kontonummer"
                required
              />
            </InputWrapper>
          </>
        )}
        {fetchBankAccount.status === 'failed' && (
          <AlertMessageContainer messageStyle="error">
            Det gick tyvärr inte att hämta dina kontouppgifter. Försök igen
            senare eller fyll i dina kontouppgifter manuellt.
          </AlertMessageContainer>
        )}
        {fetchBankAccount.status !== 'completed' &&
          values.mobileBankId.bank !== 'CUSTOM' && (
            <>
              <FetchBankAccountsButton
                validation={validation}
                fetchBankAccount={fetchBankAccount}
              />
              {showIntegrityText && <IntegrityTextArea values={values} />}
            </>
          )}
      </div>
    );
  }

  if (fetchBankAccount.qr || fetchBankAccount.autoStartToken) {
    return (
      <>
        <QRAutostartTokenWrapper
          qr={fetchBankAccount.qr}
          autoStartToken={fetchBankAccount.autoStartToken}
        />
      </>
    );
  }

  return <BankIdWrapper />;
}
