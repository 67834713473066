import React, { useCallback } from 'react';
import styled from 'styled-components';

// Helpers
import mq from '~/App/helpers/mq';
import { linkUnderline } from '~/App/helpers/mixins';
import { LoginView, loginViews } from '~/App/components/Header/constants';
import { useLoginModal } from '~/App/shared/hooks/use-login-modal';

const Wrapper = styled.ul`
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 0 1rem;

  ${mq('≥mediumLarge')`
    padding: 0 2rem;
  `};
`;

type ItemProps = {
  alternateColor: boolean;
  active: boolean;
};

const Item = styled.li<ItemProps>`
  ${linkUnderline};
  text-transform: uppercase;
  color: ${({ alternateColor, theme }) =>
    alternateColor ? theme.themeColors.primary : theme.colors.white};
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1rem 0;
  border-bottom: ${({ active }) => active && '0.5rem solid currentColor'};
  font-weight: ${({ active }) => active && '700'};

  ${mq<ItemProps>('≥mediumLarge')`
    font-size: 1.125rem;

    &::after {
      height: 0.5rem;
      background: currentColor;
    }

    &:hover,
    &:focus,
    &:active {
      &::after {
        visibility: visible;
        transform: scaleX(1);
        display: ${({ active }) => (active ? 'none' : 'initial')};
      }
    }
  `};

  &:not(:first-child) {
    margin-left: 2rem;

    ${mq('≥mediumLarge')`
      margin-left: 3rem;
    `};
  }
`;

type Props = {
  alternateColor: boolean;
};

export function Navigation({ alternateColor }: Props) {
  const loginModal = useLoginModal();

  const setLoginView = useCallback(
    (view: LoginView) => () => {
      loginModal.handlers.setLoginView(view);
    },
    [loginModal.handlers]
  );

  return (
    <Wrapper>
      <Item
        alternateColor={alternateColor}
        onClick={setLoginView(loginViews.private)}
        active={loginModal.loginView === loginViews.private}
      >
        privat
      </Item>
      <Item
        alternateColor={alternateColor}
        onClick={setLoginView(loginViews.researcher)}
        active={loginModal.loginView === loginViews.researcher}
      >
        Forskare
      </Item>
      <Item
        alternateColor={alternateColor}
        onClick={setLoginView(loginViews.caregiver)}
        active={loginModal.loginView === loginViews.caregiver}
      >
        Vårdpersonal
      </Item>
    </Wrapper>
  );
}
