import React from 'react';

// config
import { buttonStyles } from '~/App/config/buttonStyles';

// shared components
import { SubHeroText } from '../../SubHeroText';

import { PrimaryButton, ButtonWrapper } from '../../Styled';
import { ViewWrapper } from '../../ViewWrapper';
import { HeroVariant } from '../../Hero';

export function Caregiver() {
  const heroType: HeroVariant = 'caregiver';

  return (
    <ViewWrapper hero={heroType} footer>
      <SubHeroText hero={heroType} />
      <ButtonWrapper>
        <PrimaryButton
          rel="noopener noreferrer"
          target="_blank"
          buttonType="link"
          to="https://ansokan.cancerfonden.se/sbs/vard/grandid/user/login?idp=bankid.deviceChoice"
          children="Logga in hos Fenix"
        />
        <PrimaryButton
          rel="noopener noreferrer"
          target="_blank"
          buttonType="link"
          buttonStyle={buttonStyles.outlinePrimary}
          to="https://ansokan.cancerfonden.se/sbs/vard/minsida/signup"
          children="Skapa konto hos Fenix"
        />
      </ButtonWrapper>
    </ViewWrapper>
  );
}
