import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { RelatedPageItem } from '~/types/RelatedBlock';

// Helpers
import { link, heading3, description1 } from '~/App/helpers/mixins';
import { arrowLink } from '~/App/helpers/mixins';
import { assemble } from '~/lib/slug-helper';
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

import { HtmlContent } from '~/App/shared/components/HtmlContent';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  text-align: center;

  ${mq('≥tiny')`
    display: block;
  `};
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  transition: transform 0.3s ease;
`;

const FakeArrowLink = styled.div`
  ${arrowLink};
  position: absolute;
  top: 50%;
  right: 0.25rem;
  margin-top: 0;
  font-size: 1.75rem;
  line-height: 1;

  &::after {
    transform: translateY(-50%);
  }

  ${mq('≥tiny')`
    display: none;
  `};
`;

const Link = styled.div`
  ${link};
  position: relative;
  display: block;

  .no-touchevents & {
    &:focus,
    &:hover {
      ${Image} {
        transform: scale(1.1);
      }
    }
  }
`;

const TopicCardWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.primary};
`;

const TextWrapper = styled.div`
  text-align: left;
  margin-left: 1rem;
  margin-right: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 104px);

  ${mq('≥tiny')`
    text-align: center;
    margin: 0;
    max-width: none;
  `};
`;

const Figure = styled.div`
  display: block;
  max-width: 3.5rem;
  max-height: 3.5rem;
  margin: 0 auto;

  ${mq('≥tiny')`
    display: block;
    max-width: 12rem;
    max-height: 12rem;
  `};
`;

const CircleImage = styled.div`
  display: block;
  border-radius: 50%;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  z-index: 2;

  ${Image} {
    z-index: 1;
  }
`;

const Heading = styled.h3`
  ${heading3};
  margin-top: 0;
  cursor: inherit;
  line-height: 1.5;

  ${mq('≥tiny')`
    margin-top: 1.25rem;
    line-height: 1.25;
  `};
`;

const Preamble = styled.div`
  ${description1};
  margin-top: 0.25rem;
  color: ${(props) => props.theme.colors.charcoal};

  .left,
  .center,
  .right {
    text-align: left;

    ${mq('≥tiny')`
      text-align: center;
    `};
  }

  p {
    margin-bottom: 0;
  }

  P:last-child {
    margin-top: 0;

    ${mq('≥tiny')`
      margin-top: 1rem;
  `};
  }

  .arrowLink,
  .primaryButton,
  .outlinePrimaryButton {
    display: none;

    ${mq('≥tiny')`
      display: inline-block;
    `};
  }
`;

type Props = {
  item: RelatedPageItem;
  showRoundImage: boolean;
};

export function TopicCard({ showRoundImage, item }: Props) {
  const slug = item.slug;
  const heading = item.preview?.heading;
  const preamble = item.preview?.preamble;
  const contentType = item.contentType;
  const imageUrl = mediaHelper.getUrl(item.preview?.image?.secure_url || '', {
    dpr: '2.0',
    width: 192,
    height: 192,
    gravity: 'faces'
  });

  const navigate = useNavigate();

  const children = useMemo(() => {
    return (
      <Wrapper>
        {showRoundImage && imageUrl && (
          <Figure>
            <CircleImage>
              <Image src={imageUrl} alt={item.preview?.imageAlt} />
            </CircleImage>
          </Figure>
        )}

        {!showRoundImage && imageUrl && (
          <Figure>
            <Image src={imageUrl} alt={item.preview?.imageAlt} />
          </Figure>
        )}
        <TextWrapper>
          {heading && <Heading>{heading}</Heading>}
          {preamble && (
            <Preamble>
              <HtmlContent
                dangerouslySetInnerHTML={{
                  __html: preamble
                }}
              />
            </Preamble>
          )}
        </TextWrapper>
      </Wrapper>
    );
  }, [heading, imageUrl, preamble, showRoundImage, item.preview?.imageAlt]);

  const handleClick = useCallback(
    (e) => {
      if (e.target instanceof HTMLAnchorElement) return;
      navigate(assemble({ slug, contentType }));
    },
    [contentType, navigate, slug]
  );

  if (slug) {
    return (
      <Link onClick={handleClick}>
        {children}
        <FakeArrowLink />
      </Link>
    );
  }

  return <TopicCardWrapper children={children} />;
}
