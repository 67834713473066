import React from 'react';

import { LoginView } from '~/App/components/Header/constants';

import { JoinCollection } from './views/JoinCollection';
import { ConfirmEmail } from './views/ConfirmEmail';
import { ChooseEmail } from './views/ChooseEmail';
import { Newsletter } from './views/Newsletter';
import { Researcher } from './views/Researcher';
import { VerifyEmail } from './views/VerifyEmail';
import { Private } from './views/Private';
import { Email } from './views/Email';
import { Caregiver } from './views/Caregiver';

type Props = {
  view: LoginView;
};

export function View({ view }: Props) {
  switch (view) {
    case 'private':
      return <Private />;
    case 'researcher':
      return <Researcher />;
    case 'caregiver':
      return <Caregiver />;
    case 'confirmEmail':
      return <ConfirmEmail />;
    case 'joinCollection':
      return <JoinCollection />;
    case 'newsletter':
      return <Newsletter />;
    case 'chooseEmail':
      return <ChooseEmail />;
    case 'email':
      return <Email />;
    case 'verifyEmail':
      return <VerifyEmail />;
    default:
      return null;
  }
}
