import { AxiosResponse } from 'axios';
import { useCallback, useState, useMemo, ReactNode, MouseEvent } from 'react';

// Helper
import { pushGTMEvent } from '~/App/helpers/gtm-helper';
import { gtmCustomEventCategories } from '~/App/config/gtmCustomEvents';
import { mwAxios, poll } from '~/App/helpers/http';
import {
  isMobileBrowser,
  isInstagramBrowser
} from '~/App/helpers/is-mobile-browser';

import { PaymentValues } from '../../States/usePaymentValues';
import { ContactCustomerValues } from '../../States';

type Status = 'idle' | 'failed' | 'sending' | 'polling' | 'completed';

type AccountNumber = {
  accountNo: string;
  clearingNo: string;
  displayName: string;
};

export type FetchBankAccountValues = {
  status: Status;
  accountNumbers: AccountNumber[];
  qr: string;
  autoStartToken: string;
  handlers: {
    handleClick: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  };
};

type BillectaStartResponse = AxiosResponse<{
  token: string;
}>;

type BillectaStartRequest = {
  ssn: string;
  bank: string;
};

type BillectaAccountNumber = {
  account_no: string;
  clearing_no: string;
  display_name: string;
};

type BillectaPolling = {
  id: number;
  public_id: string;
  bank: string;
  ssn: string;
  status: 'success' | 'pending' | 'failed' | 'timed_out';
  created_at: string;
  updated_at: string;
  account_numbers: BillectaAccountNumber[];
  token: string;
  qr?: string;
  bank_id_autostart_token?: string;
  polled: boolean;
  autostart_token_required: boolean;
  failure_code: null | string;
  failure_message: null | string;
};

type BillectaPollingResponse = AxiosResponse<BillectaPolling>;

type Props = {
  values: PaymentValues & ContactCustomerValues;
  children: (values: FetchBankAccountValues) => ReactNode;
};

export function useFetchBankAccount({
  values
}: Omit<Props, 'children'>): FetchBankAccountValues {
  const [status, setStatus] = useState<Status>('idle');
  const [accountNumbers, setAccountNumbers] = useState<AccountNumber[]>([]);
  const [qr, setQr] = useState<string>('');
  const [autoStartToken, setAutoStartToken] = useState<string>('');

  const redirectToMobileBankId = useCallback((bankIdAutostartToken) => {
    if (isInstagramBrowser()) {
      return; // Instagram browser crash page instead if opening app
    }

    if (isMobileBrowser()) {
      const autoStartToken = bankIdAutostartToken
        ? `autostarttoken=${bankIdAutostartToken}&`
        : '';

      window.location.href = `bankid:///?${autoStartToken}redirect=null`;
    }
  }, []);

  const handleFailureOrTimeoutResponse = (
    billectaConfirmationResponse: BillectaPollingResponse
  ) => {
    setStatus('failed');

    const {
      status: requestStatus,
      failure_code: failureCode = 'n/a',
      failure_message: failureMessage = 'n/a'
    } = billectaConfirmationResponse?.data;

    if (requestStatus === 'timed_out') {
      pushGTMEvent({
        category: gtmCustomEventCategories.errors._name,
        action: gtmCustomEventCategories.errors.bankId._name,
        label:
          gtmCustomEventCategories.errors.bankId.fetchBankAccountMWTimeout._name
      });
    } else {
      pushGTMEvent({
        category: gtmCustomEventCategories.errors._name,
        action: gtmCustomEventCategories.errors.bankId._name,
        label: `${gtmCustomEventCategories.errors.bankId.fetchBankAccountBillecta._name}${failureCode}: ${failureMessage}`
      });
    }
  };

  const handleSuccessResponse = (
    billectaConfirmationResponse: BillectaPollingResponse
  ) => {
    const { account_numbers = [] } = billectaConfirmationResponse?.data;

    const accountNumbers = account_numbers.map((account) => ({
      accountNo: account.account_no,
      clearingNo: account.clearing_no,
      displayName: account.display_name
    }));

    if (accountNumbers.length > 0) {
      values.handlers.setMobileBankIdAccount(accountNumbers[0]);
    }

    setStatus('completed');
    setAccountNumbers([...accountNumbers]);
  };

  const getRequestToken = useCallback(async () => {
    const url = `/api/billecta/accounts`;
    const body = {
      ssn: values.productOptions.customerContact.ssn,
      bank: values.mobileBankId.bank,
      otherDevice: !isMobileBrowser()
    };

    const accountPostResponse = await mwAxios.post<
      BillectaStartRequest,
      BillectaStartResponse
    >(url, body);

    return accountPostResponse?.data?.token;
  }, [values.mobileBankId.bank, values.productOptions.customerContact.ssn]);

  const handleClick = useCallback(
    async (event) => {
      event.preventDefault();

      setStatus('sending');

      try {
        const requestToken = await getRequestToken();
        setStatus('polling');

        const billectaPollResponse = await poll.start({
          fn: () =>
            mwAxios.get<BillectaPolling>(
              `/api/billecta/accounts/${requestToken}`,
              {
                timeout: 15000,
                responseType: 'json'
              }
            ),
          done: ({ data }) => data.status !== 'pending',
          onEach: ({ data }) => {
            data.qr && setQr(data.qr);
            data.bank_id_autostart_token &&
              setAutoStartToken(data.bank_id_autostart_token);
          },
          timeout: 60000,
          interval: 2000
        });

        switch (billectaPollResponse.data.status) {
          case 'failed':
          case 'timed_out':
            handleFailureOrTimeoutResponse(billectaPollResponse);
            break;
          case 'success':
            handleSuccessResponse(billectaPollResponse);
            break;
          default:
            throw 'Unexpected status';
        }
      } catch (error) {
        setStatus('failed');

        pushGTMEvent({
          category: gtmCustomEventCategories.errors._name,
          action: gtmCustomEventCategories.errors.bankId._name,
          label:
            gtmCustomEventCategories.errors.bankId
              .fetchBankAccountGeneralPollingError._name
        });
      }
    },
    [
      getRequestToken,
      redirectToMobileBankId,
      values.handlers,
      handleSuccessResponse,
      handleFailureOrTimeoutResponse
    ]
  );

  return useMemo(
    () => ({
      qr,
      autoStartToken,
      status,
      accountNumbers,
      handlers: { handleClick }
    }),
    [accountNumbers, handleClick, qr, autoStartToken, status]
  );
}
