import React, { useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';

// Config
import { paymentMethods } from '~/App/config/paymentMethods.js';

import { FavourablePaymentMethodSelector } from '../components/Forms/components/FavourablePaymentMethodSelector';
import { SubmitButton } from './../components/Forms/components/SubmitButton';

import { IntegrityTextArea } from '../components/Forms/components/IntegrityTextArea';
import { AlertMessageContainer } from '../../Elements';
import { getPurchaseErrorMessage } from '../components/Forms/helpers';
import { pushGTMFormEvent } from '~/App/helpers/gtm-helper';

const IntegrityTextAreaWrapper = styled.div`
  margin: 0.5rem 0;
`;

export default function MonthlyDonationFormPartTwo({
  formContent,
  values,
  validation,
  submit,
  submitButtonText,
  partName
}) {
  const defaultPaymentMethod = useMemo(
    () => ({
      id: paymentMethods.klarnaPayments,
      name: 'Konto eller kort',
      slug: 'klarna'
    }),
    []
  );
  useEffect(() => {
    values.handlers.handlePaymentMethod(defaultPaymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPaymentMethod]);

  const pushGtmFormInteraction = useCallback(
    (method, fieldName) => {
      pushGTMFormEvent('formInteraction', {
        formName: values.gtm?.formName,
        fieldName,
        step: values.partOptions.currentPart,
        stepName: partName,
        paymentMethod:
          method.id === paymentMethods.klarnaPayments
            ? method.slug
            : method.name,
        fieldValue: method.name
      });
    },
    [values.partOptions.currentPart, partName, values.gtm?.formName]
  );

  return (
    <FavourablePaymentMethodSelector
      methods={[
        {
          id: paymentMethods.klarnaPayments,
          name: 'Konto eller kort',
          slug: 'klarna',
          favourable: true
        },
        {
          id: paymentMethods.autoGiroBankId,
          name: 'Autogiro Mobilt BankID',
          favourable: false
        },
        {
          id: paymentMethods.autoGiroSlip,
          name: 'Autogiroblankett',
          favourable: false
        }
      ]}
      formContent={formContent}
      values={values}
      validation={validation}
      pushGtmFormInteraction={pushGtmFormInteraction}
      children={
        <>
          {submit.errors && !submit.isPolling && !submit.isSending && (
            <AlertMessageContainer messageStyle="error">
              {getPurchaseErrorMessage(submit.exceptionCode)}
            </AlertMessageContainer>
          )}
          <SubmitButton
            values={values}
            validation={validation}
            submit={submit}
            children={submitButtonText}
          ></SubmitButton>
          <IntegrityTextAreaWrapper>
            <IntegrityTextArea values={values} />
          </IntegrityTextAreaWrapper>
        </>
      }
    />
  );
}
