import React, { ReactNode, useEffect, useMemo } from 'react';

import { paymentMethods } from '~/App/config/paymentMethods';

import { FavourablePaymentMethodSelector } from '../../components/Forms/components/FavourablePaymentMethodSelector';
import {
  ContactCustomerValues,
  PaymentValues,
  ProductValues
} from '../../components/States';
import { IValidation } from '../../../Validation';

type Props = {
  values: ContactCustomerValues & PaymentValues & ProductValues;
  validation: IValidation;
  submitButtons: ReactNode;
  formContent: {
    taxReductionInfoLink?: string;
  };
};

export function Payment({
  values,
  formContent,
  validation,
  submitButtons
}: Props) {
  const isRecurring = useMemo(
    () => values.productOptions.product?.period !== 'Once',
    [values.productOptions.product.period]
  );

  const defaultPaymentMethod = useMemo(
    () => ({
      id: paymentMethods.klarnaPayments,
      name: 'Konto eller kort'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRecurring]
  );

  const methods = useMemo(() => {
    if (isRecurring) {
      return [
        {
          id: paymentMethods.klarnaPayments,
          name: 'Konto eller kort',
          slug: 'klarna',
          favourable: true
        },
        {
          id: paymentMethods.autoGiroBankId,
          name: 'Autogiro Mobilt BankID',
          favourable: false
        },
        {
          id: paymentMethods.autoGiroSlip,
          name: 'Autogiroblankett',
          favourable: false
        }
      ];
    }

    return [
      {
        id: paymentMethods.swish,
        name: 'Swish',
        favourable: true
      },
      {
        id: paymentMethods.klarnaPayments,
        name: 'Konto eller kort',
        slug: 'klarna',
        favourable: true
      },
      {
        id: paymentMethods.paymentSlip,
        name: 'Inbetalningskort',
        favourable: false,
        error:
          'Väljer du Swish eller Klarna går mer av din gåva till forskningen. Inbetalningskort innebär en högre administrativ kostnad för Cancerfonden.'
      }
    ];
  }, [isRecurring]);

  useEffect(() => {
    values.handlers.handlePaymentMethod(defaultPaymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPaymentMethod]);

  return (
    <FavourablePaymentMethodSelector
      methods={methods}
      formContent={formContent}
      values={values}
      validation={validation}
      children={submitButtons}
    />
  );
}
