import styled from 'styled-components';

// Helpers
import { search } from '~/App/helpers/icons';

export const Icon = styled.span`
  &:before {
    ${search};
    font-size: 1.5rem;
  }
`;
