import React, { useMemo } from 'react';
import styled from 'styled-components';

import { BackgroundColor } from '~/types/Block';
import { RelatedPageItem } from '~/types/RelatedBlock';

// Helpers
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import { HtmlContent } from '~/App/shared/components/HtmlContent';

type WrapperProps = {
  backgroundColor: BackgroundColor;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  border-radius: 2px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor === 'none'
      ? theme.sponsorCard.alternateBackgroundColor
      : theme.sponsorCard.backgroundColor};

  ${mq('≥small')`
    padding: 2rem;
  `};
`;

const ImageWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto 1.25rem auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 26px;

  ${mq('≥small')`
    margin-bottom: 1.75rem;
  `};
`;

const Preamble = styled.div`
  padding-top: 1rem;
  margin-bottom: auto;
  font-size: 1rem;
  line-height: 1.5;
  border-top: 1px solid ${({ theme }) => theme.sponsorCard.borderColor};

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const Author = styled.div`
  display: block;
  margin-top: 1.5rem;
`;

const AuthorName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${mq('≥small')`
    font-size: 1.125rem;
  `};
`;

const AuthorTitle = styled.div`
  font-size: 0.75rem;
`;

const AuthorContent = styled.div`
  text-align: left;
`;

type Props = {
  item: RelatedPageItem;
  backgroundColor: BackgroundColor;
};

export function SponsorCard({ item, backgroundColor }: Props) {
  const preamble = item.preview?.preamble;
  const imageUrl = item.preview?.image?.secure_url || '';
  const authorName = item.author?.name;
  const authorTitle = item.author?.title;

  return useMemo(
    () => (
      <Wrapper backgroundColor={backgroundColor}>
        {imageUrl && (
          <ImageWrapper>
            <Image
              height="26"
              src={mediaHelper.getUrl(imageUrl, {
                dpr: '2.0',
                height: 26
              })}
              alt={item.preview?.imageAlt}
            />
          </ImageWrapper>
        )}
        <Preamble>
          <HtmlContent children={preamble} />
        </Preamble>
        <Author>
          <AuthorContent>
            <AuthorName>{authorName}</AuthorName>
            <AuthorTitle>{authorTitle}</AuthorTitle>
          </AuthorContent>
        </Author>
      </Wrapper>
    ),
    [
      authorName,
      authorTitle,
      backgroundColor,
      imageUrl,
      preamble,
      item.preview?.imageAlt
    ]
  );
}
