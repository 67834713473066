import React, { ChangeEventHandler, ComponentProps, RefObject } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

// Shared components
import {
  Instructions,
  Message,
  Icon,
  Label,
  Input,
  InputIcon
} from './Components';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
`;

type InputWrapperProps = {
  suffix: string;
  isValid?: boolean;
};

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: ${({ suffix }) => (!suffix ? 'none' : 'flex')};
    align-items: center;
    padding-right: ${({ isValid }) => (isValid ? '36px' : '10px')};
    content: ${({ suffix }) => {
      if (suffix === 'kr') {
        return '"kr"';
      } else if (suffix === 'km') {
        return '"km"';
      } else if (suffix === 'kr/km') {
        return '"kr/km"';
      } else if (suffix === 'time') {
        return '"HH:MM:SS"';
      } else if (suffix === 'amount') {
        return '"st"';
      } else if (suffix === 'search') {
        return '"\\e036"';
      } else if (suffix === 'år') {
        return '"år"';
      }
    }};
    font-family: ${({ suffix }) => {
      if (suffix === 'search') {
        return '"IcoMoon"';
      } else {
        return 'inherit';
      }
    }};
  }
`;

type Props = {
  type?: string;
  label?: string;
  errors?: string[];
  suffix?: string;
  isValid?: boolean;
  inputRef?: RefObject<HTMLInputElement | null>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  instructions?: string;
  iconUrl?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
} & ComponentProps<typeof Input>;

export default function Text({
  type = 'text',
  label = '',
  errors = [],
  suffix = '',
  isValid,
  inputRef,
  isInvalid,
  isDisabled = false,
  instructions = '',
  iconUrl = '',
  ...props
}: Props) {
  const id = uuid();

  return (
    <Wrapper>
      {iconUrl && <InputIcon iconUrl={iconUrl} />}
      <Icon isValid={!!(props.value && isValid && !isDisabled)} />
      <Label
        isEmpty={!props.value && !props.defaultValue}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        hasIcon={iconUrl?.length > 1}
        children={label || props.placeholder}
        htmlFor={id}
      />
      <InputWrapper suffix={suffix} isValid={props.value && isValid}>
        <Input
          isEmpty={!props.value && !props.defaultValue}
          isValid={props.value && isValid}
          isInvalid={isInvalid}
          isDisabled={isDisabled}
          disabled={isDisabled}
          ref={inputRef}
          type={type}
          hasIcon={iconUrl}
          {...props}
          id={id}
        />
      </InputWrapper>
      {errors && errors[0] ? (
        <Message isInvalid={isInvalid}>{errors[0]}</Message>
      ) : null}
      {instructions ? <Instructions>{instructions}</Instructions> : null}
    </Wrapper>
  );
}
