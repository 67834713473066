import styled from 'styled-components';

// Helpers
import { uiLogout } from '~/App/helpers/icons';
//import mq from '~/App/helpers/mq';

export const LogoutIcon = styled.span`
  &:before {
    ${uiLogout};
    font-size: 1.75rem;
    font-weight: bold;
  }
`;
