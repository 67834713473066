import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { RelatedPageItem } from '~/types/RelatedBlock';

// Helpers
import { link, heading3, description1 } from '~/App/helpers/mixins';
import { assemble } from '~/lib/slug-helper';
import mq from '~/App/helpers/mq';
import mediaHelper from '~/App/helpers/media-helper';
import { caretRight } from '~/App/helpers/icons';

import { HtmlContent } from '~/App/shared/components/HtmlContent';

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  overflow: hidden;

  ${mq('<tiny')`
    margin: -1rem;
    margin-bottom: -0.7rem ;
      background-color: ${({ theme }) => theme.colors.lightGreen};;
    &:active {
      background-color: ${({ theme }) => theme.colors.pastelGreen};;
    }
  `};

  ${mq('≥tiny')`
    display: block;
    border-radius: 5px;
  `};
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const FakeArrowLink = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.themeColors.primary};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  right: 8px;
  top: 1.72rem;
  z-index: 10;
  display: block;
  line-height: 28px;
  font-size: 1.75rem;

  &::after {
    ${caretRight};
    margin-left: 2px;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: center;
  }

  ${mq('≥tiny')`
    display: none;
  `};
`;

const Figure = styled.div`
  display: none;
  max-width: 3.5rem;
  margin: 0 auto;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  ${mq('≥tiny')`
    display: block;
    max-width: fit-content;
  `};
`;

const Link = styled.div`
  ${link};
  position: relative;
  display: block;

  ${mq('≥tiny')`
    display: block;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px; 
    transition: transform 0.3s ease;
  `};

  .no-touchevents & {
    &:focus,
    &:hover {
      ${Figure} {
        transform: scale(1.1) translateY(-4.5%);
      }
    }
  }
`;

const TopicCardWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.primary};
`;

const TextWrapper = styled.div`
  text-align: left;
  margin-left: 1rem;
  margin-right: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 104px);
  max-height: 82px;

  ${mq('≥tiny')`
    text-align: left;
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
    max-width: none;
    max-height: none;
  `};
`;

const Heading = styled.h3`
  ${heading3};
  margin-top: 1.72rem;
  margin-bottom: 1.72rem;
  cursor: inherit;
  line-height: 1.5;

  ${mq('≥tiny')`
    text-align: left;
    margin-top: 1.25rem;
    margin-bottom: 0;
    line-height: 1.25;
  `};
`;

type PreambleProps = {
  useButtonMargin?: boolean;
};

const Preamble = styled.div<PreambleProps>`
  ${description1};
  display: none;
  margin-top: 0.25rem;
  color: ${(props) => props.theme.colors.charcoal};

  ${mq('≥tiny')`
      display: inline-block;
    `};

  .left,
  .center,
  .right {
    text-align: left;

    ${mq('≥tiny')`
      text-align: center;
    `};
  }

  p {
    margin-bottom: 0;
  }

  P:last-child {
    margin-top: 0;
    padding-bottom: ${({ useButtonMargin }) =>
      useButtonMargin ? '6.875rem' : '2.3125rem'};

    ${mq('<tiny')`
      padding-bottom: 0rem;
  `};
  }

  .arrowLink {
    display: none;

    ${mq('≥tiny')`
      display: inline-block;
      margin-top: 0.75rem;
    `};
  }

  .primaryButton,
  .outlinePrimaryButton {
    display: none;

    ${mq('≥tiny')`
      display: inline-block;
      margin-top: 1.75rem;
      position: absolute;
      bottom: 2.625rem;
      left: calc(50% - 100px);
    `};
  }
`;

type Props = {
  item: RelatedPageItem;
};

export function SquaredTopicCard({ item }: Props) {
  const slug = item.slug;
  const heading = item.preview?.heading;
  const preamble = item.preview?.preamble;
  const containsButton =
    preamble?.includes('outlinePrimaryButton') ||
    preamble?.includes('primaryButton');
  const contentType = item.contentType;
  const imageUrl = mediaHelper.getUrl(item.preview?.image?.secure_url || '', {
    dpr: '2.0',
    width: 361,
    height: 161,
    gravity: 'faces'
  });

  const navigate = useNavigate();

  const children = useMemo(() => {
    return (
      <Wrapper>
        {imageUrl && (
          <Figure>
            <Image src={imageUrl} alt={item.preview?.imageAlt} />
          </Figure>
        )}
        <TextWrapper>
          {heading && <Heading>{heading}</Heading>}
          {preamble && (
            <Preamble useButtonMargin={containsButton}>
              <HtmlContent
                dangerouslySetInnerHTML={{
                  __html: preamble
                }}
              />
            </Preamble>
          )}
        </TextWrapper>
      </Wrapper>
    );
  }, [containsButton, heading, imageUrl, preamble, item.preview?.imageAlt]);

  const handleClick = useCallback(
    (e) => {
      if (e.target instanceof HTMLAnchorElement) return;
      navigate(assemble({ slug, contentType }));
    },
    [contentType, navigate, slug]
  );

  if (slug) {
    return (
      <Link onClick={handleClick}>
        {children}
        <FakeArrowLink />
      </Link>
    );
  }

  return <TopicCardWrapper children={children} />;
}
