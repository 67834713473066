import { isNaN, parseInt } from 'lodash';
import React, {
  ComponentProps,
  useCallback,
  ChangeEvent,
  useMemo
} from 'react';
import styled from 'styled-components';

type BubbleProps = {
  offset: number;
};

const Wrapper = styled.div`
  position: relative;
  margin: 0 1rem;
`;

const Input = styled.input<BubbleProps>`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.75rem;
  background: #afafaf;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 9px;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 4.25rem;
    height: 2.5rem;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.darkBlue};
    background: ${({ theme }) => theme.colors.darkBlue};
    transform: ${({ offset }) =>
      `translateX(calc(-50% + ${offset}% + (${8 - offset * 0.15}px)))`};
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 4.25rem;
    height: 2.5rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.darkBlue};
    transform: ${({ offset }) =>
      `translateX(calc(-50% + ${offset}% + (${8 - offset * 0.15}px)))`};
    //FF specific:
    border: none;
  }

  &::-ms-thumb {
    width: 4.25rem;
    height: 2.5rem;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transform: ${({ offset }) =>
      `translateX(calc(-50% + ${offset}% + (${8 - offset * 0.15}px)))`};
  }

  &::-ms-tooltip {
    display: none;
  }
`;

const Bubble = styled.output<BubbleProps>`
  color: white;
  position: absolute;
  left: ${({ offset }) => `calc(${offset}% + (${8 - offset * 0.15}px))`};
  transform: translate(-50%, 1.6rem);
  pointer-events: none;
  white-space: nowrap;
  border: none;
`;

type Props = {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
} & ComponentProps<typeof Input>;

export function RangeSlider({ onChange, min, max, value, ...props }: Props) {
  const bubbleOffset = useMemo(
    () => Number(((value - min) * 100) / (max - min)),
    [max, min, value]
  );
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const range = event.target;
      const value = parseInt(range.value);

      if (isNaN(value)) {
        return;
      }

      onChange(value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <Input
        type="range"
        {...props}
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        offset={bubbleOffset}
        aria-label="Kronor"
      />
      <Bubble children={`${value} kr`} offset={bubbleOffset} />
    </Wrapper>
  );
}
