import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';

import { useLogin } from '~/App/shared/hooks/use-login';
import { useQuery } from '~/App/shared/hooks/use-query';
import { useAuthenticationContext } from '~/App/contexts/Authentication';

import { heading3 } from '~/App/helpers/mixins';
import { users } from '~/App/helpers/http';
import { stringify } from '~/lib/query-string';

import { Spinner } from '~/App/shared/components/Elements/Spinner';
import { Redirect } from '~/App/shared/components/Redirect';

import { BankId } from '../../BankId';
import { ViewWrapper } from '../../ViewWrapper';
import { HeroVariant } from '../../Hero';

const Heading = styled.p`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  width: 100%;
`;

const Heading3 = styled.h1`
  ${heading3};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.themeColors.primary};
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
`;

type Status = 'unknown' | 'idle' | 'loading';

export function Newsletter() {
  const login = useLogin();
  const query = useQuery();
  const activated = useRef(false);
  const isAuthenticated = useAuthenticationContext((x) => x.isAuthenticated);
  const heroType: HeroVariant = 'newsletter';

  const [status, setStatus] = useState<Status>('unknown');

  const activateToken = useCallback(async (token: string) => {
    setStatus('loading');

    await users
      .activateSubscription({
        data: {
          token
        }
      })
      .catch((_) => null);

    setTimeout(() => {
      setStatus('idle');
    }, 4000);
  }, []);

  useEffect(() => {
    if (activated.current) return;
    if (!query.subscriptionToken) return;

    activated.current = true;

    activateToken(`${query.subscriptionToken}`);
  }, [activateToken, query.subscriptionToken]);

  const children = useMemo(() => {
    if (status === 'unknown') {
      return null;
    }

    if (status === 'loading') {
      return (
        <>
          <Heading3 children="Bekräftar din e-post" />
          <Spinner size="2rem" alternateColor={true} marginTop={true} />
        </>
      );
    }

    if (isAuthenticated) {
      return (
        <Redirect
          replace
          to={`${location.pathname}?${stringify(
            omit(query, ['subscriptionToken'])
          )}`}
        />
      );
    }

    return (
      <>
        <BankId beginAuthentication={login.handlers.handleLogin}>
          <Heading>Uppdatera dina kontaktuppgifter </Heading>
          <Text>
            Bekräfta gärna dina kontaktuppgifter med BankID så kan du enkelt
            uppdatera dem i framtiden.
          </Text>
        </BankId>
      </>
    );
  }, [isAuthenticated, login.handlers.handleLogin, query, status]);

  return <ViewWrapper hero={heroType} footer children={children} />;
}
