import React from 'react';
import styled from 'styled-components';

// Helpers
import { link } from '~/App/helpers/mixins';

// Shared components
import { InfoText } from './FormTypography';

const Link = styled.a`
  ${link};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const PaddedInfoText = styled(InfoText)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 1.625rem;
`;

type Variant =
  | 'standard'
  | 'monthlyDonationCard'
  | 'monthlyDonationAutogiro'
  | 'billectaPaymentTerms';

type Props = {
  anchor?: string;
  variant?: Variant;
  className?: string;
};

export function IntegrityText({
  variant = 'standard',
  anchor = '',
  ...props
}: Props) {
  const integrityPolicyHref = `/om-oss/personlig-integritet-och-cookies${anchor}`;
  const autoGiroPolicyHref = '/stod-oss/autogiro';
  const billectaPaymentTermsHref =
    'https://app.billecta.com/ht/accountinformationservices';

  switch (variant) {
    case 'standard':
      return (
        <PaddedInfoText {...props}>
          {`Genom att gå vidare samtycker jag till att mina personuppgifter
          behandlas i enlighet med `}
          <Link target="_blank" href={`${integrityPolicyHref}`}>
            Cancerfondens integritetspolicy
          </Link>
          .
        </PaddedInfoText>
      );
    case 'monthlyDonationCard':
      return (
        <PaddedInfoText {...props}>
          {`Genom att gå vidare samtycker jag till att mina personuppgifter
          behandlas i enlighet med `}
          <Link target="_blank" href={`${integrityPolicyHref}`}>
            Cancerfondens integritetspolicy
          </Link>
          {` och att mina kortuppgifter får sparas så att gåvan kan dras varje månad på det belopp jag har valt.`}
        </PaddedInfoText>
      );
    case 'monthlyDonationAutogiro':
      return (
        <PaddedInfoText {...props}>
          {`Genom att gå vidare samtycker jag till att mina personuppgifter
          behandlas i enlighet med `}
          <Link target="_blank" href={`${integrityPolicyHref}`}>
            Cancerfondens integritetspolicy
          </Link>
          {` och `}
          <Link target="_blank" href={`${autoGiroPolicyHref}`}>
            autogirovillkoren
          </Link>
          {`.`}
        </PaddedInfoText>
      );
    case 'billectaPaymentTerms':
      return (
        <PaddedInfoText {...props}>
          {`Vidare godkänner jag att en kontoinformationstjänst tillhandahålls i enlighet med `}
          <Link target="_blank" href={`${billectaPaymentTermsHref}`}>
            Billecta Payments användarvillkor
          </Link>
          {`.`}
        </PaddedInfoText>
      );
  }
}
