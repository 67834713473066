import React from 'react';
import styled from 'styled-components';

//helpers
import mq from '~/App/helpers/mq';

//hooks
import useLoginModal from '~/App/shared/hooks/use-login-modal';

//components
import Link from '~/App/shared/components/Link';
import { arrowLink } from '~/App/helpers/mixins';
import { HeroVariant } from '../Hero';

const ArrowLink = styled(Link)`
  ${arrowLink};
`;

type StylingProps = {
  hideMobile?: boolean;
};

const Styling = styled.p<StylingProps>`
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'block')};

  ${mq('≥mediumLarge')`
        display: block;
	    margin-bottom: 1.5rem;
	    font-weight: ${({ theme }) => theme.fontWeights.regular};
	    width: 100%;
    `}
`;

type SubHeroVariant = Extract<
  HeroVariant,
  'private' | 'researcher' | 'caregiver'
>;

type Props = {
  hideMobile?: boolean;
  hero: SubHeroVariant;
};

export function SubHeroText({ hideMobile, hero }: Props) {
  const loginModal = useLoginModal();

  switch (hero) {
    case 'private':
      <Styling hideMobile={hideMobile}>
        Följ vart dina pengar går, hantera dina gåvor och starta insamlingar med
        ett klick. Logga in eller skapa ett konto med BankID.
      </Styling>;
    case 'researcher':
      return (
        <Styling hideMobile={hideMobile}>
          Ansök om forskningsbidrag, utökad stöd, forskningsresor och anordning
          av kongresser, m.m.
        </Styling>
      );
    case 'caregiver':
      return (
        <Styling hideMobile={hideMobile}>
          Ansök om ekonomiskt stöd för vårdpersonal inom cancerområdet.{' '}
          <ArrowLink
            to="/rad-och-stod/ekonomiskt-stod-for-vardpersonal"
            onClick={() => {
              loginModal.handlers.toggleLogin(false);
            }}
          >
            Läs mer
          </ArrowLink>
        </Styling>
      );
    default:
      return null;
  }
}
