import React from 'react';
import styled from 'styled-components';

// Helpers
import mediaHelper from '~/App/helpers/media-helper';

// Shared components
import Link from '~/App/shared/components/Link';

const StyledLink = styled(Link)``;

const CfImage = styled.picture`
  line-height: 0;
  display: inline-block;
`;

const cfLogo =
  'https://res.cloudinary.com/cancerfonden/image/upload/v1544186351/assets/logo-cancerfonden_one-line_white.png';

const BrandNegative = () => (
  <StyledLink to="/">
    <CfImage>
      <source
        srcSet={`
            ${mediaHelper.getUrl(cfLogo, {
              height: 70
            })} 2x,
            ${mediaHelper.getUrl(cfLogo, {
              height: 35
            })} 1x
          `}
      />
      <img
        src={mediaHelper.getUrl(cfLogo, { height: 35 })}
        alt="Logotyp för Cancerfonden"
      />
    </CfImage>
  </StyledLink>
);

export default BrandNegative;
