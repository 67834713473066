import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import { heading2 } from '~/App/helpers/mixins';

import HtmlContent from '../../../HtmlContent';
import { useInViewport } from '~/App/shared/hooks/use-in-viewport';

import { Form } from './components/Form';
import { pushGTMDynamicEvent } from '~/App/helpers/gtm-helper';

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.accentYellow};
  padding: 2rem 1rem 0;

  ${mq('≥small')`
    display: flex;
    justify-content: center;
    padding-top: 6rem;
  `};
`;

const ContentWrapper = styled.div`
  ${mq('≥small')`
    flex: 1;
    padding-right: 7rem;
    padding-left: 1rem;
    max-width: 600px;
  `};

  ${mq('≥medium')`
      padding-left: 2rem;
    `};
`;

const Heading = styled.h2`
  ${heading2};
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-bottom: 1.5rem;
`;

const Disclamer = styled(HtmlContent)`
  margin-top: 1.5rem;
  margin-bottom: 2rem;

  p {
    font-size: 0.75rem;
    line-height: 2;
  }

  a {
    text-decoration: underline;
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  height: 45vw;
  margin-top: 2rem;

  ${mq('≥small')`
    flex: 1;
    height: 30vw;
    max-height: 400px;
    max-width: 600px;
    align-self: flex-end;
  `};
`;

const Image = styled.img`
  width: 100%;
`;

type Props = {
  heading: string;
  thankYouText: string;
  disclamer: string;
};

export function NewsletterBlock({ heading, thankYouText, disclamer }: Props) {
  const [isVisible, ref] = useInViewport({ threshold: 0.5 });
  const [seenBlock, setSeenBlock] = useState(false);

  useEffect(() => {
    if (!isVisible || seenBlock) return;
    pushGTMDynamicEvent({
      event: 'NewsLetterBlock',
      category: 'Nyhetsbrev',
      action: 'impression',
      label: window?.location.href
    });
    setSeenBlock(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Background ref={ref}>
      <ContentWrapper>
        <Heading children={heading} />
        <Form thankYouText={thankYouText} />
        <Disclamer dangerouslySetInnerHTML={{ __html: disclamer }} />
      </ContentWrapper>
      <ImageWrapper>
        <Image
          src="https://res.cloudinary.com/cancerfonden/image/upload/v1618847307/assets/icons-communication-newsletter.svg"
          alt="Illustration av ett brev"
        />
      </ImageWrapper>
    </Background>
  );
}
