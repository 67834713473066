import 'core-js/stable';

import React from 'react';
import { hydrate } from 'react-dom';
import { Location } from 'history';
import { BrowserRouter } from 'react-router-dom';

import { onDocumentReady } from './lib/on-document-ready';

import DataFetching from './client-data-fetching';
import App from './App';
import { ContentRedirect } from './App/shared/components/ContentRedirect';
import { AppContext, AppContextValue } from './App/contexts/App';
import { Providers } from '~/App/contexts';
import { IPreloadedState } from './types/IPreloadedState';
import { AppRoutes } from './App/shared/components/AppRoutes';

declare global {
  interface Window {
    __PRELOADED_STATE__?: IPreloadedState;
    analytics?: any;
  }
}

onDocumentReady(() => {
  const preloadedState: IPreloadedState = {
    authentication: {
      status: 'idle',
      state: 'anonymous',
      errors: [],
      user: null,
      ...window.__PRELOADED_STATE__?.authentication
    },
    ab: {
      cohortIds: {},
      ...window.__PRELOADED_STATE__?.ab
    },
    app: {
      status: 'idle',
      pageContent: {},
      cookies: {},
      stickyNavigation: true,
      ...window.__PRELOADED_STATE__?.app,
      userAgent: navigator.userAgent
    },
    shoppingCart: {
      items: [],
      shippingPrice: 0,
      ...window.__PRELOADED_STATE__?.shoppingCart
    },
    helloBar: window.__PRELOADED_STATE__?.helloBar || null
  };

  delete window.__PRELOADED_STATE__;

  function onTransition() {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }

  function onLoading(context: AppContextValue) {
    return function () {
      context.setStatus('loading');
    };
  }

  function onComplete(context: AppContextValue) {
    return function () {
      context.setStatus('idle');
    };
  }

  function onData(context: AppContextValue) {
    return function (location: Location, data: unknown) {
      return context.setPageContent((pageContent) => ({
        ...pageContent,
        [location.pathname]: data
      }));
    };
  }

  hydrate(
    <React.StrictMode>
      <BrowserRouter>
        <Providers state={preloadedState}>
          <AppContext.Consumer>
            {(context) => (
              <ContentRedirect>
                <DataFetching
                  pageContent={context.pageContent}
                  onTransition={onTransition}
                  onLoading={onLoading(context)}
                  onComplete={onComplete(context)}
                  onData={onData(context)}
                >
                  <App>
                    <AppRoutes />
                  </App>
                </DataFetching>
              </ContentRedirect>
            )}
          </AppContext.Consumer>
        </Providers>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('site-wrapper')
  );
});
