import React, { useState, ChangeEvent, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { buttonStyles } from '~/App/config/buttonStyles';

import { Modal, PrimaryButton } from '~/App/shared/components/Elements';
import Checkbox from '~/App/shared/components/Fields/Checkbox';
import DateField from '~/App/shared/components/Fields/Date';
import Text from '~/App/shared/components/Fields/Text';
import TextArea from '~/App/shared/components/Fields/TextArea';
import { useValidation } from '~/App/shared/components/Validation';
import { mq } from '~/lib/mq';
import { IDeceasedContact } from '~/types/IDeceasedContact';
import { FormSubHeading } from '../../../../../components/Forms/components/FormTypography';
import { FuneralHomeSelect } from './components/FuneralHomeSelect';
import { heading4 } from '~/App/helpers/mixins';

const Wrapper = styled.div`
  padding: 1rem;

  ${mq('<small')`
    padding: 0;
  `}
`;

const PaddingWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const HalfPaddingWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: calc(50% - 0.25rem);

  ${mq('<small')`
    width: 100%;
  `}
`;

const Heading = styled.h2`
  font-size: 1.375rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  margin: 2rem 0 1rem;
  color: #4a4a4a;

  ${mq('<small')`
    font-size: 1.125rem;
  `}
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 3.25rem;

  ${mq('<small')`
    font-size: 1rem;
    margin-bottom: 3rem;
  `}
`;

const RowSpread = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BottomRowSpread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RequiredInputText = styled.p`
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #ba0913;
`;

const FlexSpreader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const TextAreaInfo = styled.p`
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #888;
  margin-top: 0.5rem;
`;

const ManualInfo = styled.div`
  font-size: 0.9375em;
  margin-top: 0.5rem;
  margin-left: 0.25rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-left: 0.25rem;
  column-gap: 1.5625rem;
`;

const ErrorText = styled.p`
  font-size: 0.875rem;
  margin-left: 0.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ba0913;
`;

const FormLabel = styled.label`
  ${heading4};
  font-weight: bold;
  padding-bottom: 0.25rem;
`;

type Props = {
  closeModal: () => void;
  onSubmit: (value: IDeceasedContact) => void;
  value: IDeceasedContact | null;
};

export function NewPersonModal({ closeModal, onSubmit, value }: Props) {
  const [person, setPerson] = useState<IDeceasedContact>(
    value || {
      funeral: { place: '', date: null },
      funeralHome: { name: '', id: undefined, isChurch: undefined },
      birth: { date: null },
      note: '',
      death: null,
      firstName: '',
      lastName: ''
    }
  );

  const validation = useValidation({
    values: person,
    schema: {
      firstName: {
        presence: {
          allowEmpty: false,
          message: '^Glöm inte ange personens förnamn'
        }
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: '^Glöm inte ange personens efternamn'
        }
      },
      'funeral.place': {
        presence: { allowEmpty: false, message: '^Glöm inte ange ort' }
      },
      'funeralHome.name': {
        presence: {
          allowEmpty: false,
          message: '^Glöm inte att fylla i begravningsbyrå eller kyrka'
        }
      },
      'funeralHome.isChurch': () => {
        if (!person.funeralHome?.id) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Vänligen ange ett av alternativen för att gå vidare.'
            }
          };
        }
      }
    }
  });

  const todaysDate = useMemo(() => new Date(), []);
  const anyDateBeforeToday = useMemo(
    () =>
      new Date(
        todaysDate.getFullYear() - 150,
        todaysDate.getMonth(),
        todaysDate.getDay()
      ),
    [todaysDate]
  );
  const minDate = useMemo(() => new Date(), []);
  const maxDate = useMemo(
    () =>
      new Date(minDate.getFullYear(), minDate.getMonth() + 6, minDate.getDay()),
    [minDate]
  );

  const handleSubmit = useCallback(() => {
    if (!validation.isValidated) {
      return validation.showAllErrors();
    }
    onSubmit(person);
    closeModal && closeModal();
  }, [validation, onSubmit, person, closeModal]);

  return (
    <Modal closeModal={closeModal} closeOnOverlayClick={false}>
      <Wrapper>
        <Heading>Vem vill du hedra?</Heading>
        <Paragraph>
          Vi hjälper dig gärna att leverera minnesbladet till rätt ställe. Ju
          fler uppgifter du kan ange desto snabbare går det. Du får också
          minnesbladet skickat till din e-post.
        </Paragraph>
        <RowSpread>
          <FormSubHeading>Avlidnes namn</FormSubHeading>
          {!(
            validation.isValid('lastName') && validation.isValid('firstName')
          ) && <RequiredInputText>Obligatorisk</RequiredInputText>}
        </RowSpread>
        <FlexSpreader>
          <HalfPaddingWrapper>
            <Text
              value={person.firstName}
              maxLength="50"
              placeholder="Förnamn"
              onBlur={validation.showError.bind(undefined, 'firstName')}
              errors={validation.errors['firstName']}
              isValid={validation.isValid('firstName')}
              isInvalid={validation.isInvalid('firstName')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPerson({ ...person, firstName: event.target.value });
              }}
              required
            />
          </HalfPaddingWrapper>
          <HalfPaddingWrapper>
            <Text
              value={person.lastName}
              maxLength="50"
              placeholder="Efternamn"
              onBlur={validation.showError.bind(undefined, 'lastName')}
              errors={validation.errors['lastName']}
              isValid={validation.isValid('lastName')}
              isInvalid={validation.isInvalid('lastName')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPerson({ ...person, lastName: event.target.value });
              }}
              required
            />
          </HalfPaddingWrapper>
        </FlexSpreader>
        <PaddingWrapper>
          <RowSpread>
            <FormSubHeading>Bostads- eller begravningsort</FormSubHeading>
            {!validation.isValid('funeral.place') && (
              <RequiredInputText>Obligatorisk</RequiredInputText>
            )}
          </RowSpread>
          <Text
            value={person.funeral?.place}
            maxLength="50"
            placeholder="Ort"
            onBlur={validation.showError.bind(undefined, 'funeral.place')}
            errors={validation.errors['funeral.place']}
            isValid={validation.isValid('funeral.place')}
            isInvalid={validation.isInvalid('funeral.place')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPerson({
                ...person,
                funeral: { ...person.funeral, place: event.target.value }
              })
            }
            required
          />
        </PaddingWrapper>
        <PaddingWrapper>
          <RowSpread>
            <FormLabel htmlFor="react-select-funeral-home-input">
              Begravningsbyrå eller kyrka
            </FormLabel>
            {!validation.isValid('funeralHome.name') && (
              <RequiredInputText>Obligatorisk</RequiredInputText>
            )}
          </RowSpread>
          <FuneralHomeSelect
            value={person.funeralHome}
            validation={validation}
            validationKey="funeralHome.name"
            onChange={(value) =>
              setPerson({
                ...person,
                funeralHome: value
              })
            }
          />
          {!person.funeralHome?.id && person.funeralHome?.name && (
            <FlexSpreader>
              <ManualInfo>
                Namnet finns inte sökbart i vårt register, men du kan använda
                det ändå
              </ManualInfo>
              <CheckboxWrapper>
                <Checkbox
                  name="not-church"
                  checked={
                    person.funeralHome?.isChurch === undefined
                      ? false
                      : !person.funeralHome?.isChurch
                  }
                  onChange={() => {
                    setPerson({
                      ...person,
                      funeralHome: { ...person.funeralHome, isChurch: false }
                    });
                  }}
                  isInvalid={validation.isInvalid('funeralHome.isChurch')}
                >
                  <span>Begravningsbyrå</span>
                </Checkbox>
                <Checkbox
                  name="church"
                  checked={
                    person.funeralHome?.isChurch === undefined
                      ? false
                      : Boolean(person.funeralHome?.isChurch)
                  }
                  onChange={() => {
                    setPerson({
                      ...person,
                      funeralHome: { ...person.funeralHome, isChurch: true }
                    });
                  }}
                  isInvalid={validation.isInvalid('funeralHome.isChurch')}
                >
                  <span>Kyrka</span>
                </Checkbox>
              </CheckboxWrapper>
            </FlexSpreader>
          )}
          {validation.isInvalid('funeralHome.isChurch') && (
            <ErrorText>{validation.errors['funeralHome.isChurch']}</ErrorText>
          )}
        </PaddingWrapper>
        <FormSubHeading>Begravningsdatum</FormSubHeading>
        <PaddingWrapper>
          <DateField
            value={person.funeral?.date}
            onChange={(value: Date) =>
              setPerson({
                ...person,
                funeral: { ...person.funeral, date: value }
              })
            }
            max={maxDate}
            min={todaysDate}
            placeholder="åååå-mm-dd"
            showMonthYearDropdown={true}
          />
        </PaddingWrapper>
        <PaddingWrapper>
          <FormSubHeading>Födelsedatum</FormSubHeading>
          <DateField
            value={person.birth?.date}
            onChange={(value: Date) =>
              setPerson({
                ...person,
                birth: { ...person.birth, date: value }
              })
            }
            max={todaysDate}
            min={anyDateBeforeToday}
            placeholder="åååå-mm-dd"
            showMonthYearDropdown={true}
          />
        </PaddingWrapper>
        <FormSubHeading>Dödsdatum</FormSubHeading>
        <PaddingWrapper>
          <DateField
            value={person.death?.date}
            onChange={(value: Date) =>
              setPerson({
                ...person,
                death: { ...person.death, date: value }
              })
            }
            max={todaysDate}
            min={anyDateBeforeToday}
            placeholder="åååå-mm-dd"
            showMonthYearDropdown={true}
          />
        </PaddingWrapper>
        <PaddingWrapper>
          <FormSubHeading>
            Annan info som kan hjälpa oss att skicka minnesbladet rätt
          </FormSubHeading>
          <TextArea
            value={person.note}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPerson({ ...person, note: event.target.value })
            }
            placeholder="Skriv här"
            maxLength="2000"
          />
          <TextAreaInfo>
            Till exempel namn, adress eller e-post till anhörig
          </TextAreaInfo>
        </PaddingWrapper>
        <BottomRowSpread>
          <PrimaryButton
            onClick={closeModal}
            buttonStyle={buttonStyles.outlinePrimary}
            children="Avbryt"
          ></PrimaryButton>
          <PrimaryButton
            onClick={handleSubmit}
            children="Bekräfta"
            buttonStyle={
              validation.isValidated
                ? buttonStyles.success
                : buttonStyles.disabled
            }
          ></PrimaryButton>
        </BottomRowSpread>
      </Wrapper>
    </Modal>
  );
}
